import { notEmpty } from "@product/scmp-sdk";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { checkHasLiveTag } from "scmp-app/components/article/article-render/helpers";
import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import { getContentSponsor } from "scmp-app/components/content/content-sponsor-tag/helpers";
import type { homeSecondaryContentItemContent$key } from "scmp-app/queries/__generated__/homeSecondaryContentItemContent.graphql";

import {
  ActionBar,
  Container,
  CoverImage,
  Header,
  Headline,
  SponsorTagContainer,
  StyledCoverEntityLink,
  StyledHeadlineEntityLink,
  SubHeadline,
  Summary,
} from "./styles";

export type Props = {
  reference: homeSecondaryContentItemContent$key;
  skipHighlight?: boolean;
  withBookmark?: boolean;
  withComment?: boolean;
  withImage?: boolean;
  withLiveTag?: boolean;
  withSection?: boolean;
  withSubHeadline?: boolean;
  withSummary?: boolean;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      onClick,
      reference: reference_,
      skipHighlight = false,
      withBookmark = false,
      withComment = false,
      withImage = false,
      withLiveTag = false,
      withSection = false,
      withSubHeadline = false,
      withSummary = false,
    },
    reference,
  ) => {
    const content = useFragment(
      graphql`
        fragment homeSecondaryContentItemContent on Content {
          entityId
          urlAlias
          ...entityLink
          ...helpersCheckHasLiveTagArticle
          ...helpersUseContentSponsorContent
          ...hooksContentItemProvidersContent
            @arguments(
              withSections: true
              withHeadline: true
              withLiveTag: true
              withSummary: true
              withSubHeadline: true
              withActionBar: true
              withCoverImage: true
              withCoverImageSize1200x800: true
              withCoverImageSize540x360: true
              withSponsorTag: true
              withVideoDurationOverlay: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    const hasLiveTag = checkHasLiveTag(content);
    const isShowLiveTag = hasLiveTag && withLiveTag;

    const contentSponsor = getContentSponsor(content);
    const isSponsorArticle = notEmpty(contentSponsor?.name);

    return (
      <Container className={className} ref={reference}>
        {(isShowLiveTag || withSection) && (
          <Header>
            {providers.liveTag({ hide: !isShowLiveTag })}
            {providers.sections({ hide: isShowLiveTag || !withSection })}
          </Header>
        )}
        <StyledHeadlineEntityLink
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
          reference={content}
        >
          <Headline>
            {providers.headline({
              hideTooltip: true,
              preferSocialHeadline: true,
              skipHighlight,
            })}
          </Headline>
          {withSummary && (
            <Summary>
              {providers.summary({
                isTextOverflowEllipsis: true,
              })}
            </Summary>
          )}
          {withSubHeadline && !isSponsorArticle && (
            <SubHeadline>{providers.subHeadline()}</SubHeadline>
          )}
        </StyledHeadlineEntityLink>

        {isSponsorArticle ? (
          <SponsorTagContainer>{providers.sponsorTag?.({ variant: "home" })}</SponsorTagContainer>
        ) : (
          <ActionBar>
            {providers.actionBar({
              commentVariant: "reverse",
              isBookmarkVisible: withBookmark ?? false,
              isCommentsVisible: withComment ?? false,
            })}
          </ActionBar>
        )}
        {withImage && (
          <StyledCoverEntityLink
            $isSponsorArticle={isSponsorArticle}
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
            reference={content}
          >
            <CoverImage>
              {providers.coverImage({
                responsiveVariants: {
                  desktopUp: "size1200x800",
                  mobileUp: "size540x360",
                  tabletUp: "size1200x800",
                },
              })}
              {providers.video?.durationOverlay({
                iconSize: "hidden",
                variant: "homepage",
              })}
            </CoverImage>
          </StyledCoverEntityLink>
        )}
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeSecondary";

export const ContentItemHomeSecondary = withHighlightedHeadline(Component);
